<template>
  <nav>
    <ol class="list">
      <li
        v-for="(step, index) in steps"
        :key="step.route"
        class="item"
      >
        <router-link
          :to="{ name: `request-step-${index}` }"
          class="link"
          :class="{
            'is-completed': step.completed
          }"
        >
          {{ step.title }}
        </router-link>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'CRequestSteps',

  computed: {
    steps () {
      return [
        {
          title: 'Antragstellung',
          completed: this.$store.state.steps[0]?.completed
        },
        {
          title: 'Zusammenfassung',
          completed: this.$store.state.steps[1]?.completed
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@use "../css/variables.scss";

.list {
  list-style: none;
  counter-reset: request-steps;

  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.item {
  counter-increment: request-steps;
  margin-right: 0.75em;
  margin-bottom: 0.75em;
}

.link {
  display: inline-block;
  padding-right: 0.625em;

  text-decoration: none;
  color: inherit;

  background: variables.$color-gray-200;
  border: 0.125em solid transparent;
  border-radius: 0.875em;

  transition: border-color 0.15s;

  &:hover {
    border-color: variables.$color-gray-400;
  }

  &::before {
    content: counter(request-steps);

    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    margin: -0.125rem;
    margin-right: 0.25em;

    font-size: 0.875em;
    font-weight: 700;
    line-height: 1;
    color: variables.$color-gray-700;

    background: variables.$color-gray-400;
    border-radius: 50%;

    transition:
      color 0.15s,
      background-color 0.15s;
  }

  &.is-active::before {
    color: #fff;
    background: variables.$color-primary;
  }

  &.is-completed::before {
    content: "✔";
  }
}
</style>
