<template>
  <div>
    <c-request-steps class="mb-8" />

    <c-message
      v-if="hasErrors"
      class="mb-8"
      type="error"
    >
      <p>Bitte korrigieren Sie alle Felder, die mit einer Fehlermeldung markiert sind. Gehen Sie hierzu auch nochmals alle Schritte durch, da gegebenenfalls dort Felder nicht korrekt ausgefüllt wurden.</p>
      <p
        v-if="errorDetails"
        class="mt-3"
      >
        <strong>Fehlermeldung:</strong> {{ errorDetails }}
      </p>
    </c-message>

    <router-view />
  </div>
</template>

<script>
import CMessage from '../components/CMessage.vue'
import CRequestSteps from '../components/CRequestSteps.vue'

export default {
  name: 'VRequest',

  components: {
    CMessage,
    CRequestSteps
  },

  computed: {
    hasErrors () {
      return !!Object.keys(this.$store.state.errors).length
    },
    errorDetails () {
      return this.$store.state.errors.detail
    }
  }
}
</script>
